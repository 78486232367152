const productsData = [
    {
        id: 1,
        tag: "hero-product",
        tagline: "Keep the noise out, or in. You choose.",
        heroImage: "/images/NewProducts/product-1.png",
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "JBL",
        title: "UKNIT Best Choice",
        info: "Wireless Over-Ear NC Headphones",
        category: "Headphones",
        type: "Over Ear",
        connectivity: "Wireless",
        finalPrice: 9999,
        originalPrice: 14999,
        quantity: 1,
        ratings: 1234,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 2,
        tag: "featured-product",
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "boAt",
        title: "Discover Your New Favorite T-Shirt with UKNIT!",
        info: "Elevate Your Style with UKNIT's Premium T-Shirts!",
        category: "Headphones",
        type: "On Ear",
        connectivity: "Wireless",
        finalPrice: 1299,
        originalPrice: 3990,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 3,
        tag: "hero-product",
        tagline: "Fassion of comfort all-day.",
        heroImage: "/images/NewProducts/product-2.png",
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "boAt",
        title: "Comfort Meets Fashion in UKNIT T-Shirts!",
        info: "Comfort Meets Fashion in UKNIT T-Shirts!",
        category: "Earbuds",
        type: "In Ear",
        connectivity: "Wireless",
        finalPrice: 1099,
        originalPrice: 2990,
        quantity: 1,
        ratings: 1244,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 4,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "boAt",
        title: "boAt BassHeads 110",
        info: "In-Ear Wired Earphones",
        category: "Earphones",
        type: "In Ear",
        connectivity: "Wired",
        finalPrice: 449,
        originalPrice: 999,
        quantity: 1,
        ratings: 556,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 5,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "boAt",
        title: "Stay Stylish and Comfortable with UKNIT Tees!",
        info: "Stay Stylish and Comfortable with UKNIT Tees!",
        category: "Headphones",
        type: "On Ear",
        connectivity: "Bluetooth & Wired",
        finalPrice: 1599,
        originalPrice: 2990,
        quantity: 1,
        ratings: 1563,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 6,
        images: [
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
            "/images/NewProducts/product-6.png",
        ],
        brand: "JBL",
        title: "Upgrade Your Look with UKNIT's Trendy T-Shirts!",
        info: "Upgrade Your Look with UKNIT's Trendy T-Shirts!",
        category: "Neckbands",
        type: "In Ear",
        connectivity: "Wireless",
        finalPrice: 3699,
        originalPrice: 5299,
        quantity: 1,
        ratings: 836,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 7,
        tag: "hero-product",
        tagline: "Experience Ultimate Comfort with UKNIT T-Shirts!",
        heroImage: "/images/NewProducts/product-3.png",
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "Sony",
        title: "Express Yourself with UKNIT's Fashionable T-Shirts!",
        info: "Express Yourself with UKNIT's Fashionable T-Shirts!",
        category: "Headphones",
        type: "Over Ear",
        connectivity: "Wireless",
        finalPrice: 13489,
        originalPrice: 19990,
        quantity: 1,
        ratings: 679,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 8,
        tag: "featured-product",
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "JBL",
        title: "UKNIT T-Shirts: Where Style Meets Comfort!" ,
        info: "UKNIT T-Shirts: Where Style Meets Comfort!",
        category: "Headphones",
        type: "Over Ear",
        connectivity: "Wireless",
        finalPrice: 5999,
        originalPrice: 7999,
        quantity: 1,
        ratings: 755,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 9,
        tag: "featured-product",
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "boAt",
        title: "Make a Statement with UKNIT's Unique T-Shirts!",
        info: "Make a Statement with UKNIT's Unique T-Shirts!",
        category: "Neckbands",
        type: "In Ear",
        connectivity: "Wireless",
        finalPrice: 899,
        originalPrice: 2990,
        quantity: 1,
        ratings: 1464,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 10,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "JBL",
        title: "Refresh Your Wardrobe with UKNIT T-Shirts!",
        info: "Refresh Your Wardrobe with UKNIT T-Shirts!",
        category: "Earbuds",
        type: "In Ear",
        connectivity: "Wireless",
        finalPrice: 2999,
        originalPrice: 6999,
        quantity: 1,
        ratings: 801,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 11,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "Sony",
        title: "UKNIT T-Shirts: Perfect for Every Occasion!",
        info: "Step Up Your Style Game with UKNIT Tees!",
        category: "Earbuds",
        type: "In Ear",
        connectivity: "Wireless",
        finalPrice: 19990,
        originalPrice: 24990,
        quantity: 1,
        ratings: 382,
        rateCount: 3,
        path: "/product-details/",
    },
    {
        id: 12,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "boAt",
        title: "UKNIT: Your Ultimate T-Shirt Destination!",
        info: "UKNIT: Your Ultimate T-Shirt Destination!",
        category: "Earphones",
        type: "In Ear",
        connectivity: "Wired",
        finalPrice: 649,
        originalPrice: 1190,
        quantity: 1,
        ratings: 1178,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 13,
        tag: "featured-product",
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "JBL",
        title: "Find Your Perfect Fit with UKNIT T-Shirts!",
        info: "Find Your Perfect Fit with UKNIT T-Shirts!",
        category: "Earphones",
        type: "In Ear",
        connectivity: "Wired",
        finalPrice: 999,
        originalPrice: 1599,
        quantity: 1,
        ratings: 1144,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 14,
        tag: "featured-product",
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "boAt",
        title: "Find Comfort and Style with UKNIT T-Shirts!",
        info: "Find Comfort and Style with UKNIT T-Shirts!",
        category: "Earbuds",
        type: "In Ear",
        connectivity: "Wireless",
        finalPrice: 1074,
        originalPrice: 3999,
        quantity: 1,
        ratings: 1340,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 15,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "Sony",
        title: "UKNIT: Your Go-To Brand for Stylish T-Shirts!",
        info: "UKNIT: Your Go-To Brand for Stylish T-Shirts!",
        category: "Headphones",
        type: "Over Ear",
        connectivity: "Wireless",
        finalPrice: 8520,
        originalPrice: 14990,
        quantity: 1,
        ratings: 853,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 16,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "JBL",
        title: "Unleash Your Style with UKNIT's T-Shirt Collection!",
        info: "Unleash Your Style with UKNIT's T-Shirt Collection!",
        category: "Headphones",
        type: "On Ear",
        connectivity: "Wireless",
        finalPrice: 3282,
        originalPrice: 3999,
        quantity: 1,
        ratings: 364,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 17,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "boAt",
        title: "Stay Cool and Stylish with UKNIT T-Shirts!",
        info: "UKNIT T-Shirts: Designed for Comfort and Style!",
        category: "Earbuds",
        type: "In Ear",
        connectivity: "Wireless",
        finalPrice: 1699,
        originalPrice: 4990,
        quantity: 1,
        ratings: 1011,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 18,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "Sony",
        title: "Sony MDR-EX14AP",
        info: "In-Ear Wired Earphones",
        category: "Earphones",
        type: "In Ear",
        connectivity: "Wired",
        finalPrice: 549,
        originalPrice: 1290,
        quantity: 1,
        ratings: 530,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 19,
        images: [
            "/images/NewProducts/product-2.png",
            "/images/NewProducts/product-3.png",
            "/images/NewProducts/product-4.png",
            "/images/NewProducts/product-5.png",
        ],
        brand: "Sony",
        title: "Upgrade to UKNIT T-Shirts for a Stylish Look!",
        info: "Upgrade to UKNIT T-Shirts for a Stylish Look!",
        category: "Neckbands",
        type: "In Ear",
        connectivity: "Wireless",
        finalPrice: 2690,
        originalPrice: 4990,
        quantity: 1,
        ratings: 474,
        rateCount: 4,
        path: "/product-details/",
    },
];

export default productsData;